/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

//import prosemirror styles
// @import 'prosemirror-menu/style/menu.css';
// @import 'prosemirror-view/style/prosemirror.css';
// @import 'prosemirror-example-setup/style/style.css';

// @import 'prosemirror-view/style/prosemirror.css';
// @import 'prosemirror-example-setup/style/style.css';
@import 'ngx-toastr/toastr';

.my-scrollbar {
    @apply scrollbar-thin scrollbar-thumb-slate-400 scrollbar-track-slate-50 scrollbar-thumb-rounded-sm;
}

.left-bar::after {
    content: '';

    @apply absolute left-0 top-0 h-full w-0.5 bg-black;

}

.right-bar::after {
    content: '';

    @apply absolute right-0 top-0 h-full w-0.5 bg-black;

}



.bg-stripes {
    background-color: #7fa8b409;
    background-image: linear-gradient(135deg, #b9ccf127 10%, #0000 0, #0000 50%, #b9ccf127 0, #b9ccf127 60%, #0000 0, #0000);
    background-size: 7.07px 7.07px;
}

:focus-visible {
    @apply outline-none;
}

.button-primary {
    @apply font-medium inline-flex items-center rounded-md disabled:bg-gray-600 bg-indigo-600 text-white shadow-sm hover:bg-indigo-700 disabled:hover:bg-gray-600 disabled:cursor-not-allowed focus:outline-none focus:ring-2 px-2.5 py-1.5 text-xs
}

.button-secondary {
    
    @apply font-medium inline-flex items-center rounded-md border border-transparent text-gray-900 disabled:bg-gray-500 bg-gray-100 shadow-sm hover:bg-gray-200 disabled:hover:bg-gray-300 disabled:cursor-not-allowed focus:outline-none focus:ring-2 px-2.5 py-1.5 text-xs
}

@media print {
    @page {
        size: A4;
        size: landscape;
        // overflow:hidden;
    }

    html {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
}